// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-screenmenu-tsx": () => import("./../../../src/pages/screenmenu.tsx" /* webpackChunkName: "component---src-pages-screenmenu-tsx" */),
  "component---src-pages-ueber-uns-tsx": () => import("./../../../src/pages/ueber-uns.tsx" /* webpackChunkName: "component---src-pages-ueber-uns-tsx" */),
  "component---src-templates-footer-seite-tsx": () => import("./../../../src/templates/footer-seite.tsx" /* webpackChunkName: "component---src-templates-footer-seite-tsx" */),
  "component---src-templates-menu-tsx": () => import("./../../../src/templates/menu.tsx" /* webpackChunkName: "component---src-templates-menu-tsx" */),
  "component---src-templates-seite-tsx": () => import("./../../../src/templates/seite.tsx" /* webpackChunkName: "component---src-templates-seite-tsx" */)
}

